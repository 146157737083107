import includes from 'lodash/includes';
import React, { useState, useRef, useEffect } from 'react';
import injectSheet from 'react-jss';

const styles = {
    dialogueContainer: {
        position: 'relative',
    },
    dialogue: {
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: '2px 2px 6px 4px rgba(0, 0, 0, 0.06)',
        padding: '22px 27px 21px',
        position: 'absolute',
        zIndex: 3,
        top: 40,
        right: 0,
    },
};

const Dialogue = ({
    closeDialogue,
    closeOnChildAction,
    customClass,
    classes,
    children,
}: {
    closeDialogue: () => void;
    closeOnChildAction: boolean;
    customClass?: string;
    classes: Record<string, string>;
    children: React.ReactNode;
}): React.ReactElement => {
    const dialogueRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseDown = (e: MouseEvent): void => {
            if (
                dialogueRef !== null &&
                dialogueRef.current !== null &&
                e !== null &&
                e.target !== null &&
                e.target instanceof HTMLElement &&
                dialogueRef.current.contains(e.target)
            ) {
                return;
            }
            closeDialogue();
        };

        const handleClick = (e: MouseEvent): void => {
            if (
                dialogueRef !== null &&
                dialogueRef.current !== null &&
                e !== null &&
                e.target instanceof HTMLElement &&
                dialogueRef.current.contains(e.target) &&
                includes(['a', 'button'], e.target.tagName.toLowerCase())
            ) {
                closeDialogue();
            }
        };

        document.addEventListener('mousedown', handleMouseDown, true);
        if (closeOnChildAction) {
            document.addEventListener('click', handleClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleMouseDown, true);
            if (closeOnChildAction) {
                document.removeEventListener('click', handleClick);
            }
        };
    }, [closeDialogue, closeOnChildAction]);

    return (
        <div className={`${classes.dialogue} ${customClass}`} ref={dialogueRef}>
            {children}
        </div>
    );
};

Dialogue.defaultProps = {
    customClass: '',
};

const ButtonDialogue = ({
    trigger,
    dialogueClass,
    closeOnChildAction,
    classes,
    children,
}: {
    trigger: React.ReactElement;
    dialogueClass?: string;
    closeOnChildAction?: boolean;
    classes: Record<string, string>;
    children: React.ReactNode;
}): React.ReactElement => {
    const [open, setOpen] = useState(false);

    const dialogueTrigger = React.cloneElement(trigger, {
        onClick: () => setOpen(!open),
        className: `${trigger.props.className} ${open ? 'active' : ''}`,
    });
    return (
        <div className={classes.dialogueContainer}>
            {dialogueTrigger}
            {open && (
                <Dialogue
                    closeDialogue={() => setOpen(false)}
                    customClass={dialogueClass}
                    classes={classes}
                    closeOnChildAction={!!closeOnChildAction}
                >
                    {children}
                </Dialogue>
            )}
        </div>
    );
};

ButtonDialogue.defaultProps = {
    dialogueClass: '',
    closeOnChildAction: false,
};

export default injectSheet(styles)(ButtonDialogue);
