export const requestNotificationPermission = (): void => {
    if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission();
    }
};

const createNotification = (title: string, body: string): Notification => {
    const notification = new Notification(title, {
        body,
        icon: '/images/android-icon-192x192.png',
    });
    notification.onclick = () => {
        window.focus();
        notification.close();
    };
    return notification;
};

export const showNotification = (title: string, body: string): void => {
    if ('Notification' in window && Notification.permission === 'granted') {
        createNotification(title, body);
    }
};
