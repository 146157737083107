import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { useTranslation } from 'react-i18next';
import { IClient } from '../../interfaces/IClient';
import { NotificationState } from '../../interfaces/INotification';
import { CoordinateSystem, Measurement } from './ConfigEnums';
import ConfigurationDialogue from './ConfigurationDialogue';
import ClientRow from './ClientRow';
import DeletionDialogue from './DeletionDialogue';
import Notification from '../Notification';
import useNotifications from '../hooks/useNotifications';
import loadingIcon from '../../images/ic-refresh.svg';

const styles = {
    clientsTitle: {
        height: 64,
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 18,
        alignItems: 'center',
        '& > h4': {
            fontSize: 16,
            fontWeight: 800,
            lineHeight: 1.94,
            letterSpacing: 0.36,
        },
    },
    connectingMessage: {
        marginLeft: 16,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '& > img': {
            marginRight: 5,
            width: 16,
        },
        '& > p': {
            fontSize: 10,
            fontWeight: 'bold',
            lineHeight: 1.8,
            letterSpacing: 0.23,
            color: '#888880',
        },
    },
    clients: {
        backgroundColor: 'white',
        borderRadius: '10px',
        flexGrow: '1',
        height: 100, // just some value for it to not overflow
    },
    clientsContent: {
        height: 'calc(100% - 116px)',
        overflow: 'hidden auto',
        position: 'relative',
    },
    '@keyframes notificationIn': {
        from: {
            opacity: 0,
            bottom: 0,
        },
        to: {
            opacity: 1,
            bottom: 14,
        },
    },
    '@keyframes notificationOut': {
        from: {
            opacity: 1,
            bottom: 14,
        },
        to: {
            opacity: 0,
            bottom: 28,
        },
    },
    notification: {
        position: 'absolute',
        zIndex: 2,
        width: 'calc(100% - 124px)',
        left: 62,
    },
    activeNotification: {
        extend: 'notification',
        animation: '$notificationIn 0.2s',
        animationIterationCount: 1,
        bottom: 14,
    },
    inactiveNotification: {
        extend: 'notification',
        animation: '$notificationOut 0.2s',
        animationIterationCount: 1,
        bottom: 24,
        opacity: 0,
    },
    clientsFooter: {
        width: 'calc(100% - 32px)',
        height: 52,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        boxShadow: '0 2px 6px 4px rgba(0, 0, 0, 0.1)',
        '& > p, & > a': {
            fontSize: 14,
            lineHeight: 1.29,
            letterSpacing: 0.4,
        },
        '& > a': {
            fontWeight: 800,
        },
    },
};

const ClientsCard = injectSheet(styles)(
    ({
        classes,
        clients,
        connected,
        focusedClient,
        highlightedClient,
        onDeleteAll,
        onDeleteOne,
        onFocus,
    }: {
        classes: Record<string, string>;
        clients: IClient[];
        connected: boolean;
        focusedClient?: number;
        highlightedClient?: number;
        onDeleteAll?: () => void;
        onDeleteOne?: (id: number) => void;
        onFocus?: (i: number) => void;
    }): React.ReactElement => {
        const [coordinateSystem, setCoordinateSystem] = useState(
            CoordinateSystem.WGS84,
        );
        const [measurement, setMeasurement] = useState(Measurement.METER);
        const [notifications, addNotification] = useNotifications(
            {},
            2000,
            250,
        );
        const { t } = useTranslation('translation');

        const notificationElements: React.ReactElement[] = [];
        if (notifications && notifications.current) {
            Object.keys(notifications.current).forEach((key) => {
                if (notifications.current && notifications.current[key]) {
                    notificationElements.push(
                        <Notification
                            notification={notifications.current[key]}
                            className={
                                notifications.current[key].state ===
                                NotificationState.ACTIVE
                                    ? classes.activeNotification
                                    : classes.inactiveNotification
                            }
                            key={key}
                        />,
                    );
                }
            });
        }

        return (
            <div className={classes.clients}>
                <div className={classes.clientsTitle}>
                    <h4>{t('clients.title')} </h4>
                    <div className={classes.connectingMessage}>
                        {!connected && (
                            <>
                                <img alt="" src={loadingIcon} />
                                <p>{t('messages.websocketConnecting')}</p>
                            </>
                        )}
                    </div>
                    <ConfigurationDialogue
                        measurement={measurement}
                        setMeasurement={setMeasurement}
                        coordinateSystem={coordinateSystem}
                        setCoordinateSystem={setCoordinateSystem}
                    />
                    {onDeleteAll !== undefined && (
                        <DeletionDialogue onDelete={onDeleteAll} />
                    )}
                </div>
                <div className={classes.clientsContent}>
                    {clients.map(
                        (c): React.ReactElement => (
                            <ClientRow
                                client={c}
                                key={c.id}
                                measurement={measurement}
                                coordinateSystem={coordinateSystem}
                                addNotification={addNotification}
                                onClick={
                                    onFocus !== undefined
                                        ? () => onFocus(c.id)
                                        : undefined
                                }
                                onDelete={onDeleteOne}
                                active={
                                    c.id === focusedClient ||
                                    c.id === highlightedClient
                                }
                            />
                        ),
                    )}
                    {notificationElements}
                </div>
                <div className={classes.clientsFooter}>
                    <p>{t('clients.footerTagline')}</p>
                </div>
            </div>
        );
    },
);

ClientsCard.defaultProps = {
    focusedClient: undefined,
    highlightedClient: undefined,
    onDeleteAll: undefined,
    onDeleteOne: undefined,
    onFocus: undefined,
};

export default ClientsCard;
