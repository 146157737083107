import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { ISearchResult } from '../../../interfaces/ISearchResult';
import ITheme from '../../../interfaces/ITheme';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    entry: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        padding: '27px 39px 8px 18px',
        fontSize: 19,
        lineHeight: 1.63,
        letterSpacing: 0.43,
    },
    name: {
        flexGrow: 1,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    button: {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: theme.link,
        fontSize: 16,
        fontWeight: 800,
        lineHeight: 1.5,
        letterSpacing: 0.25,
        marginLeft: 40,
    },
});

const SearchEntry = ({
    person,
    onSelect,
    classes,
}: {
    person: ISearchResult;
    onSelect: (id: number) => void;
    classes: Record<string, string>;
}): React.ReactElement => {
    const { t } = useTranslation('translation');
    return (
        <div className={classes.entry}>
            <div className={classes.name}>{person.name}</div>
            <div>{person.phone}</div>
            <button
                type="button"
                aria-label={t('actions.show')}
                className={classes.button}
                onClick={() => onSelect(person.id)}
            >
                {t('actions.show')}
            </button>
        </div>
    );
};

export default injectSheet(styles)(SearchEntry);
