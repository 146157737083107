import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import ITheme from '../../interfaces/ITheme';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    wrapper: {
        height: '100%',
        position: 'relative',
        minWidth: 147,
        textAlign: 'right',
        marginLeft: 24,
        '&:hover $trigger, $trigger.active, $trigger.focus-visible': {
            '& $arrow': {
                transform: 'rotate(-135deg)',
            },
            '& + $dropdown': {
                display: 'block',
            },
        },
    },
    trigger: {
        border: 'none',
        backgroundColor: 'transparent',
        height: '100%',
        padding: '19px 24px',
        fontSize: 14,
        lineHeight: 2.43,
        letterSpacing: 0.4,
        color: theme.light,
        '&:hover, &.active': {
            backgroundColor: theme.primaryHover,
        },
        '&.focus-visible': {
            border: `2px solid ${theme.light}`,
            padding: '17px 22px',
        },
    },
    arrow: {
        border: `solid ${theme.light}`,
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: 3,
        transform: 'rotate(45deg)',
        transition: 'transform 0.2s',
        marginLeft: 20,
    },
    dropdown: {
        position: 'absolute',
        zIndex: 99,
        top: 72,
        right: 0,
        margin: 0,
        padding: 0,
        display: 'none',
        backgroundColor: theme.primary,
        listStyle: 'none',
        minWidth: 178,
        '& > li': {
            margin: 0,
            padding: 0,
            '& > button': {
                width: '100%',
                height: '100%',
                textAlign: 'left',
                border: 'none',
                backgroundColor: 'transparent',
                fontSize: 14,
                lineHeight: 1,
                letterSpacing: 0.4,
                color: theme.light,
                position: 'relative',
                padding: '18px 24px',
                '&:hover': {
                    backgroundColor: theme.primaryHover,
                },
                '&.active::after': {
                    content: '""',
                    width: 24,
                    height: 0,
                    borderBottom: `1px solid #fafaf9`,
                    position: 'absolute',
                    left: 24,
                    bottom: 11,
                },
            },
        },
    },
});

const LanguageDropdown = ({
    classes,
}: {
    classes: Record<string, string>;
}): React.ReactElement => {
    const { t, i18n } = useTranslation('translation');

    const changeLanguage = (lang: string): void => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className={classes.wrapper}>
            <button
                type="button"
                aria-label={t('header.languages')}
                className={classes.trigger}
            >
                {t('header.languages')}
                <span className={classes.arrow} />
            </button>
            <ul className={classes.dropdown}>
                <li>
                    <button
                        type="button"
                        aria-label="Deutsch"
                        onClick={() => changeLanguage('de')}
                        className={i18n.language === 'de' ? 'active' : ''}
                    >
                        Deutsch
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        aria-label="Français"
                        onClick={() => changeLanguage('fr')}
                        className={i18n.language === 'fr' ? 'active' : ''}
                    >
                        Français
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        aria-label="Italiano"
                        onClick={() => changeLanguage('it')}
                        className={i18n.language === 'it' ? 'active' : ''}
                    >
                        Italiano
                    </button>
                </li>
                <li>
                    <button
                        type="button"
                        aria-label="English"
                        onClick={() => changeLanguage('en')}
                        className={i18n.language === 'en' ? 'active' : ''}
                    >
                        English
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default injectSheet(styles)(LanguageDropdown);
