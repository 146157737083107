export enum CoordinateSystem {
    WGS84,
    LV95,
}

export enum Measurement {
    // noinspection JSUnusedGlobalSymbols
    METER,
    FEET,
}
