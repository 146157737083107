import React from 'react';
import injectSheet from 'react-jss';
import { useTranslation } from 'react-i18next';
import SmsLocatorForm from './SmsLocatorForm';

const styles = {
    cardTitle: {
        height: 64,
        display: 'flex',
        paddingLeft: 16,
        paddingRight: 18,
        alignItems: 'center',
        '& > h4': {
            fontSize: 16,
            fontWeight: 800,
            lineHeight: 1.94,
            letterSpacing: 0.36,
            flexGrow: 1,
        },
    },
    smsLocator: {
        backgroundColor: 'white',
        borderRadius: '10px',
        flexGrow: '0',
        marginBottom: 24,
    },
};

const SmsLocatorCard = injectSheet(styles)(
    ({ classes }: { classes: Record<string, string> }): React.ReactElement => {
        const { t } = useTranslation('translation');
        return (
            <div className={classes.smsLocator}>
                <div className={classes.cardTitle}>
                    <h4>{t('emergencyLocator.smsLocator.title')}</h4>
                </div>
                <SmsLocatorForm />
            </div>
        );
    },
);

export default SmsLocatorCard;
