import { CoordinateSystem } from './ConfigEnums';

// Convert DEC angle to SEX DMS
const DECtoSEX = (angle: number): number => {
    // Extract DMS
    const deg = Math.round(angle);
    const min = (Math.round(angle) - deg) * 60;
    const sec = ((angle - deg) * 60 - min) * 60;

    // Result in degrees sex (dd.mmss)
    return deg + min / 100 + sec / 10000;
};

// Convert Degrees angle to seconds
const DEGtoSEC = (angle: number): number => {
    // Extract DMS
    const deg = Math.round(angle);
    const min = (Math.round(angle) - deg) * 100;
    const sec = ((angle - deg) * 100 - min) * 100;

    // Result in degrees sex (dd.mmss)
    return sec + min * 60 + deg * 3600;
};

const WGS84toLV95Aux = (
    lat: number,
    lng: number,
): { latAux: number; lngAux: number } => {
    // Converts degrees dec to sex
    const sexLat = DECtoSEX(lat);
    const sexLng = DECtoSEX(lng);

    // Converts degrees to seconds (sex)
    const secLat = DEGtoSEC(sexLat);
    const secLng = DEGtoSEC(sexLng);

    // Auxiliary values (% Bern)
    const latAux = (secLat - 169028.66) / 10000;
    const lngAux = (secLng - 26782.5) / 10000;
    return { latAux, lngAux };
};

// Convert WGS84 lat/long (� dec) to LV95 y
const WGS84toLV95y = (lat: number, lng: number): number => {
    const { latAux, lngAux } = WGS84toLV95Aux(lat, lng);

    return (
        2600072.37 +
        211455.93 * lngAux -
        10938.51 * lngAux * latAux -
        0.36 * lngAux * latAux ** 2 -
        44.54 * lngAux ** 3
    );
};

// Convert WGS84 lat/long (� dec) to LV95 x
const WGS84toLV95x = (lat: number, lng: number): number => {
    const { latAux, lngAux } = WGS84toLV95Aux(lat, lng);

    return (
        1200147.07 +
        308807.95 * latAux +
        3745.25 * lngAux ** 2 +
        76.63 * latAux ** 2 -
        194.56 * lngAux ** 2 * latAux +
        119.79 * latAux ** 3
    );
};

// return a nicely formatted display string for WGS84
const getWGS84DisplayString = (lat: number, lon: number): string => {
    let lngUnit = 'W';
    let lanUnit = 'N';

    if (lon > 0) {
        lngUnit = 'E';
    }

    if (lat < 0) {
        lanUnit = 'S';
    }
    return `${lat.toFixed(5)}°\u00A0${lanUnit} / ${lon.toFixed(
        5,
    )}°\u00A0${lngUnit}`;
};

const getWGS84CopyString = (lat: number, lon: number): string =>
    `${lat.toFixed(5)}, ${lon.toFixed(5)}`;

// return a nicely formatted display string for LV95
const getLV95DisplayString = (x: number, y: number): string =>
    `${x.toFixed(0)} / ${y.toFixed(0)}`;

const getLV95CopyString = (x: number, y: number): string =>
    `${x.toFixed(0)}, ${y.toFixed(0)}`;

export const getCoordinatesString = (
    lat: number,
    lon: number,
    coordinateSystem: CoordinateSystem,
): string => {
    if (coordinateSystem === CoordinateSystem.LV95) {
        return getLV95DisplayString(
            WGS84toLV95y(lat, lon),
            WGS84toLV95x(lat, lon),
        );
    }

    return getWGS84DisplayString(lat, lon);
};

export const getCopyCoordinatesString = (
    lat: number,
    lon: number,
    coordinateSystem: CoordinateSystem,
): string => {
    if (coordinateSystem === CoordinateSystem.LV95) {
        return getLV95CopyString(
            WGS84toLV95y(lat, lon),
            WGS84toLV95x(lat, lon),
        );
    }

    return getWGS84CopyString(lat, lon);
};
