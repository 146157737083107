import React, { useRef, useState } from 'react';
import {
    INotificationWithState,
    INotification,
    NotificationState,
} from '../../interfaces/INotification';

interface INotificationCollection {
    [key: string]: INotificationWithState;
}

function useNotifications(
    initialNotifications: INotificationCollection,
    activeTime: number,
    inactiveTime: number,
): [React.RefObject<INotificationCollection>, (n: INotification) => void] {
    const ref = useRef(initialNotifications);
    const [, forceRender] = useState(false);

    function deleteNotification(key: string): void {
        delete ref.current[key];
        forceRender((s) => !s);
    }

    function inactivateNotification(key: string): void {
        ref.current[key].state = NotificationState.INACTIVE;
        setTimeout(() => deleteNotification(key), inactiveTime);
        forceRender((s) => !s);
    }

    function addNotification(notification: INotification): void {
        const key = Date.now().toString();
        ref.current[key] = { ...notification, state: NotificationState.ACTIVE };
        setTimeout(() => inactivateNotification(key), activeTime);
        forceRender((s) => !s);
    }

    return [ref, addNotification];
}

export default useNotifications;
