import React, { useState, useEffect } from 'react';
import injectSheet from 'react-jss';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';
import { Message } from '@stomp/stompjs';
import Map from '../Map/Map';
import ClientsCard from '../Clients/ClientsCard';
import SmsLocatorCard from './SmsLocator/SmsLocatorCard';
import { ICheckin } from '../../interfaces/ICheckin';
import { IClient } from '../../interfaces/IClient';
import { useAuth } from '../../oauth/privateRoute';
import { showNotification } from '../../utils/Notifications';
import useStompWS from '../../stomp/useStompWS';

const API = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
const PANEL_WIDTH = 604;

const styles = {
    dashboard: {
        width: '100%',
        height: 'calc(100vh - 72px)',
        position: 'relative',
    },
    panel: {
        width: `${PANEL_WIDTH - 24}px`,
        position: 'absolute',
        top: '40px',
        left: '24px',
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
};

interface IMessage {
    checkins: ICheckin[];
}

const EmergencyLocator = ({
    classes,
}: {
    classes: Record<string, string>;
}): React.ReactElement => {
    const [recipientId, setRecipientId] = useState<number | undefined>(
        undefined,
    );
    const [connected, setConnected] = useState(false);
    const [clients, setClients] = useState<IClient[]>([]);
    const [focusedClient, setFocusedClient] = useState<number | undefined>(
        undefined,
    );
    const [sentNotifications, setSentNotifications] = useState<number[]>([]);
    const auth = useAuth();
    const { t } = useTranslation('translation');

    const onMessage = (m: Message): void => {
        const message: IMessage | undefined = JSON.parse(m.body);
        if (!message || !message.checkins) return;
        const newClients = message.checkins.map((c) => {
            return {
                id: c.checkinId,
                timestamp: c.alarmTimestamp,
                phoneNumber: c.caller.phoneNumber,
                name: `${c.caller.firstname ? c.caller.firstname : ''} ${
                    c.caller.lastname ? c.caller.lastname : ''
                }`.trim(),
                device: c.device,
                locations: [c.location],
                additionalProperties: c.additionalProperties,
            };
        });
        const clientsWithoutNotification = filter(
            newClients,
            (c) => !includes(sentNotifications, c.id),
        );
        if (clientsWithoutNotification.length > 0) {
            showNotification(
                t('notifications.newCheckin.title'),
                `${clientsWithoutNotification[0].phoneNumber}, ${clientsWithoutNotification[0].locations[0].address}`,
            );
            setSentNotifications([
                ...sentNotifications,
                ...clientsWithoutNotification.map((c) => c.id),
            ]);
        }
        setClients([
            ...orderBy(newClients, ['timestamp'], ['desc']),
            ...clients,
        ]);
    };

    useStompWS({
        url: `${API.replace(/^(http)/, 'ws')}/v1/live`,
        topics: recipientId
            ? [{ topic: `/checkins/${recipientId}`, onMessage }]
            : [],
        connectHeaders: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        onConnect: () => {
            setClients([]);
            setConnected(true);
        },
        onDisconnect: () => setConnected(false),
    });

    useEffect((): void => {
        fetch(`${API}/v1/webapi/recipient`, {
            headers: {
                Authorization: `Bearer ${auth.user?.accessToken}`,
            },
        })
            .then((response) => {
                if (response.status === 401 || response.status === 403) {
                    auth.signin();
                    return Promise.reject();
                }
                return response.json();
            })
            .then(
                (result) => {
                    setRecipientId(result);
                },
                (error) => console.error(error),
            );
    }, [auth]);

    const onDelete = (): void => {
        setClients([]);
        setFocusedClient(undefined);
    };

    return (
        <div className={classes.dashboard}>
            <Map
                panelWidth={PANEL_WIDTH}
                clients={clients}
                focusedClient={focusedClient}
                setFocusedClient={setFocusedClient}
            />
            <div className={classes.panel}>
                <SmsLocatorCard />
                <ClientsCard
                    clients={clients}
                    connected={connected}
                    onDeleteAll={onDelete}
                    onFocus={setFocusedClient}
                    focusedClient={focusedClient}
                />
            </div>
        </div>
    );
};

export default injectSheet(styles)(EmergencyLocator);
