import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { NotificationType, INotification } from '../interfaces/INotification';
import ITheme from '../interfaces/ITheme';
import checkIcon from '../images/ic-check.svg';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    notification: {
        backgroundColor: theme.primary,
        borderRadius: 4,
        padding: '4px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > p': {
            fontSize: 15,
            fontWeight: 'bold',
            lineHeight: 2.07,
            letterSpacing: 0.34,
            color: theme.light,
        },
    },
    notificationError: {
        extend: 'notification',
        backgroundColor: theme.error,
    },
    icon: {
        marginRight: 6,
    },
});

const Notification = ({
    className,
    notification,
    classes,
}: {
    className: string;
    notification: INotification;
    classes: Record<string, string>;
}): React.ReactElement => (
    <div
        className={`${className} ${
            notification.type === NotificationType.ERROR
                ? classes.notificationError
                : classes.notification
        }`}
    >
        {notification.type === NotificationType.SUCCESS && (
            <img alt="" src={checkIcon} className={classes.icon} />
        )}
        <p>{notification.text}</p>
    </div>
);

export default injectSheet(styles)(Notification);
