import React, { useState } from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button';
import { ISearchResult } from '../../../interfaces/ISearchResult';
import ITheme from '../../../interfaces/ITheme';
import { useAuth } from '../../../oauth/privateRoute';

import searchIcon from '../../../images/ic-search.svg';

const phoneRegex = /^\+?[0-9()\s-]+$/;
const API = process.env.REACT_APP_API_URL_MISSING_PERSON
    ? process.env.REACT_APP_API_URL_MISSING_PERSON
    : '';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    formWrapper: {
        display: 'flex',
        width: '100%',
        boxSizing: 'border-box',
        '&:focus-within, &:focus-within': {
            '& $input': {
                border: `1px solid ${theme.link}`,
                borderRight: 'none',
                padding: '7px 17px 8px',
                backgroundColor: 'transparent',
                '& + $button': {
                    padding: [11, 19],
                    border: `1px solid ${theme.link}`,
                    borderLeft: 'none',
                    backgroundColor: 'transparent',
                },
            },
            '& $invalidInput': {
                borderColor: theme.error,
                '& + $button': {
                    borderColor: theme.error,
                },
            },
        },
    },
    input: {
        position: 'relative',
        flexGrow: 1,
        backgroundColor: '#fafaf9',
        borderRadius: '4px 0 0 4px',
        border: 'none',
        outline: 'none',
        boxSizing: 'border-box',
        height: 48,
        width: '100%',
        fontSize: 19,
        lineHeight: 1.63,
        letterSpacing: 0.43,
        padding: '8px 18px 9px',
        margin: 0,
        caretColor: theme.link,
        '&::placeholder': {
            color: '#888880',
        },
    },
    invalidInput: {
        extend: 'input',
        padding: '7px 17px 8px',
        border: `1px solid ${theme.error}`,
        borderRight: 'none',
        '& + $button': {
            padding: [11, 19],
            border: `1px solid ${theme.error}`,
            borderLeft: 'none',
        },
    },
    button: {
        borderRadius: '0 4px 4px 0',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fafaf9',
        lineHeight: 1.5,
        letterSpacing: 0.25,
        padding: [12, 20],
        '& > img': {
            marginLeft: 10,
            height: 20,
            width: 'auto',
        },
        '&:hover, &:active, &.active, &:focus': {
            backgroundColor: '#fafaf9',
            color: theme.link,
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
});

const SearchForm = ({
    setFoundPersons,
    setErrorMessage,
    classes,
}: {
    setFoundPersons: (p: ISearchResult[], b?: boolean) => void;
    setErrorMessage: (m: string) => void;
    classes: Record<string, string>;
}): React.ReactElement => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const { t } = useTranslation('translation');

    const onChangeInput = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setFoundPersons([]);
        setPhoneNumber(event.target.value);
    };

    const auth = useAuth();

    const onSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        if (phoneRegex.test(phoneNumber)) {
            fetch(
                `${API}/v1/missingperson?query=${phoneNumber
                    .replace(/[()\s-]+/g, '')
                    .replace(/\+/g, '%2B')}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth.user?.accessToken}`,
                    },
                },
            )
                .then((response) => {
                    if (response.status === 401 || response.status === 403) {
                        auth.signin();
                        return Promise.reject();
                    }
                    return response.json();
                })
                .then(
                    (result) => setFoundPersons(result.persons, true),
                    () => setErrorMessage(t('missingPersonSearch.fetchError')),
                );
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.formWrapper}>
                <input
                    value={phoneNumber}
                    type="text"
                    name="phoneNumber"
                    onChange={onChangeInput}
                    className={
                        phoneNumber && !phoneRegex.test(phoneNumber)
                            ? classes.invalidInput
                            : classes.input
                    }
                    placeholder={t('messages.phonePlaceholder')}
                />
                <Button
                    label={t('actions.search')}
                    submit
                    className={classes.button}
                    light
                >
                    <span>{t('actions.search')}</span>
                    <img alt="" src={searchIcon} />
                </Button>
            </div>
        </form>
    );
};

export default injectSheet(styles)(SearchForm);
