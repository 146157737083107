import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import injectSheet, { Styles } from 'react-jss';
import ITheme from '../../interfaces/ITheme';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    inactive: {
        height: '100%',
        '& > a': {
            display: 'block',
            height: '100%',
            boxSizing: 'border-box',
            padding: '24px 18px 29px',
            fontSize: 19,
            fontWeight: 300,
            lineHeight: 1,
            color: theme.light,
            textDecoration: 'none',
            '&:hover, &:active, &:focus': {
                color: theme.light,
                border: 'none',
                textDecoartion: 'none',
                boxShadow: 'none',
                backgroundColor: theme.primaryHover,
            },
            '&.focus-visible': {
                border: `2px solid ${theme.light}`,
                padding: '22px 16px 27px',
            },
        },
    },
    active: {
        extend: 'inactive',
        '& > a': {
            position: 'relative',
            '&:after': {
                content: '""',
                width: 32,
                position: 'absolute',
                borderBottom: `1px solid ${theme.light}`,
                bottom: 19,
                left: 18,
            },
            '&.focus-visible:after': {
                left: 16,
                bottom: 17,
            },
        },
    },
});

const NavLink = ({
    label,
    to,
    classes,
}: {
    label: string;
    to: string;
    classes: Record<string, string>;
}): React.ReactElement => {
    const match = useRouteMatch({ path: to, exact: true });

    return (
        <div className={match ? classes.active : classes.inactive}>
            <Link to={to}>{label}</Link>
        </div>
    );
};

export default injectSheet(styles)(NavLink);
