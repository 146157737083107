import React, { SyntheticEvent } from 'react';
import injectSheet, { Styles } from 'react-jss';
import ITheme from '../interfaces/ITheme';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    default: {
        border: 'none',
        whiteSpace: 'nowrap',
        '&.focus-visible': {
            border: `2px solid ${theme.light}`,
        },
        '& > img.dark': {
            display: 'block',
        },
        '& > img.light': {
            display: 'none',
        },
        '&:hover, &:active, &.active, &:focus': {
            '& > img.dark': {
                display: 'none',
            },
            '& > img.light': {
                display: 'block',
            },
        },
    },
    dark: {
        backgroundColor: theme.link,
        color: theme.light,
        '&:hover, &:active, &.active, &:focus': {
            backgroundColor: theme.linkHover,
        },
        '&.focus-visible': {
            boxShadow: `0 0 0 2pt ${theme.linkHover}`,
        },
        '&:disabled': {
            backgroundColor: '#c0c0c0',
        },
    },
    light: {
        backgroundColor: theme.lightButton,
        color: theme.link,
        '&:hover, &:active, &.active, &:focus': {
            backgroundColor: theme.linkHover,
            color: theme.light,
        },
        '&.focus-visible': {
            boxShadow: `0 0 0 2pt ${theme.linkHover}`,
        },
    },
    large: {
        fontSize: 16,
        lineHeight: 1.19,
        letterSpacing: 0.2,
        padding: '15px 20px 16px 21px',
        fontWeight: 800,
        borderRadius: 8,
        '&.focus-visible': {
            padding: '13px 18px 14px 19px',
        },
    },
    small: {
        fontSize: 12,
        lineHeight: 2,
        letterSpacing: 0.19,
        padding: '4px 8px',
        fontWeight: 800,
        borderRadius: 4,
        height: 32,
        '&.focus-visible': {
            padding: '2px 6px',
        },
    },
    darkLarge: {
        extend: ['default', 'dark', 'large'],
    },
    darkSmall: {
        extend: ['default', 'dark', 'small'],
    },
    lightLarge: {
        extend: ['default', 'light', 'large'],
    },
    lightSmall: {
        extend: ['default', 'light', 'small'],
        color: theme.primary,
    },
});

const ButtonElement = ({
    submit,
    small,
    light,
    onClick,
    classes,
    children,
    className,
    label,
    disabled,
    ...rest
}: {
    submit?: boolean;
    small?: boolean;
    light?: boolean;
    onClick?: (e?: SyntheticEvent) => void | undefined;
    classes: Record<string, string>;
    children: React.ReactNode;
    className?: string;
    label: string;
    disabled?: boolean;
}): React.ReactElement => {
    let buttonClass = '';
    if (!small && !light) buttonClass = classes.darkLarge;
    if (small && !light) buttonClass = classes.darkSmall;
    if (!small && light) buttonClass = classes.lightLarge;
    if (small && light) buttonClass = classes.lightSmall;
    return (
        <button
            type={submit ? 'submit' : 'button'}
            aria-label={label}
            className={`${className} ${buttonClass}`}
            onClick={onClick}
            disabled={disabled}
            {...rest}
        >
            {children}
        </button>
    );
};
ButtonElement.defaultProps = {
    submit: false,
    small: false,
    light: false,
    onClick: undefined,
    className: '',
    disabled: false,
};

const LinkButtonElement = ({
    small,
    light,
    classes,
    children,
    className,
    ...rest
}: {
    small?: boolean;
    light?: boolean;
    classes: Record<string, string>;
    children: React.ReactNode;
    className?: string;
}): React.ReactElement => {
    let buttonClass = '';
    if (!small && !light) buttonClass = classes.darkLarge;
    if (small && !light) buttonClass = classes.darkSmall;
    if (!small && light) buttonClass = classes.lightLarge;
    if (small && light) buttonClass = classes.lightSmall;
    return (
        <a className={`${className} ${buttonClass}`} {...rest}>
            {children}
        </a>
    );
};
LinkButtonElement.defaultProps = {
    small: false,
    light: false,
    className: '',
};

export const Button = injectSheet(styles)(ButtonElement);
export const LinkButton = injectSheet(styles)(LinkButtonElement);
