import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import includes from 'lodash/includes';
import Header from './components/Header/Header';
import EmergencyLocator from './components/EmergencyLocator/EmergencyLocator';
import MissingPersonSearch from './components/MissingPersonSearch/MissingPersonTool';
import { useAuth } from './oauth/privateRoute';
import { requestNotificationPermission } from './utils/Notifications';

const App = (): React.ReactElement => {
    const [error, setError] = useState<string | undefined>(undefined);
    const auth = useAuth();
    if (error === undefined && !auth.isSignedIn()) {
        auth.signin().catch((e) => setError(e ? e.toString() : undefined));
    }
    const loggedIn = auth.isSignedIn();
    if (error !== undefined) return <div>{error}</div>;

    if (loggedIn) requestNotificationPermission();

    return (
        <RecoilRoot>
            <Router>
                <Header />
                {loggedIn && (
                    <Switch>
                        {includes(auth.authorities, 'MISSING_PERSON') && (
                            <Route path="/missingperson">
                                <MissingPersonSearch />
                            </Route>
                        )}
                        <Route path="/">
                            <EmergencyLocator />
                        </Route>
                    </Switch>
                )}
            </Router>
        </RecoilRoot>
    );
};

export default App;
