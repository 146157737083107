import React, { useEffect, useCallback, useRef } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import PositionElements from './PositionElements';
import { IClient } from '../../interfaces/IClient';
import { defaultCenter } from './Map';

const Positions = ({
    panelWidth,
    clients,
    focusedClient,
    setFocusedClient,
    drawPath,
    onMouseoverPolyline,
    onMouseoutPolyline,
    ...rest
}: {
    panelWidth: number;
    clients: IClient[];
    focusedClient?: number;
    setFocusedClient?: (i: number) => void;
    drawPath: boolean;
    onMouseoverPolyline?: (i: number) => void;
    onMouseoutPolyline?: (i: number) => void;
}): React.ReactElement => {
    const previousClient = useRef<number | undefined>(undefined);
    const map = useGoogleMap();
    const { google } = window;

    const repositionMap = useCallback((): void => {
        if (!map || !google) return;

        const locations = filter(
            clients.map(
                (c) => orderBy(c.locations, ['timestamp'], ['desc'])[0],
            ),
            (l) => !!l,
        );
        if (focusedClient) {
            if (previousClient.current === focusedClient) return;

            previousClient.current = focusedClient;
            const focusedIndex = findIndex(
                clients,
                (c) => c.id === focusedClient,
            );
            if (
                focusedIndex === -1 ||
                locations[focusedIndex] === undefined ||
                locations[focusedIndex].lat === undefined ||
                locations[focusedIndex].lon === undefined
            )
                return;

            map.setZoom(15);
            const scale = 2 ** (map.getZoom() || 0);
            const latlng = new google.maps.LatLng(
                locations[focusedIndex].lat,
                locations[focusedIndex].lon,
            );
            const worldCoordinateCenter = map
                .getProjection()
                ?.fromLatLngToPoint(latlng);
            if (!worldCoordinateCenter) return;
            const worldCoordinateNewCenter = new google.maps.Point(
                worldCoordinateCenter.x - panelWidth / 2 / scale,
                worldCoordinateCenter.y,
            );
            const newCenter = map
                .getProjection()
                ?.fromPointToLatLng(worldCoordinateNewCenter);
            if (!newCenter) return;
            map.panTo(newCenter);
        } else if (locations.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < locations.length; i += 1) {
                const latlng = new google.maps.LatLng(
                    locations[i].lat,
                    locations[i].lon,
                );
                bounds.extend(latlng);
            }
            if (locations.length === 1) {
                const extendPoint1 = new google.maps.LatLng(
                    bounds.getNorthEast().lat() + 0.01,
                    bounds.getNorthEast().lng() + 0.01,
                );
                const extendPoint2 = new google.maps.LatLng(
                    bounds.getNorthEast().lat() - 0.01,
                    bounds.getNorthEast().lng() - 0.01,
                );
                bounds.extend(extendPoint1);
                bounds.extend(extendPoint2);
            }
            map.fitBounds(bounds, 24);
            const scale = 2 ** (map.getZoom() || 0);
            const worldCoordinateSouthWest = map
                .getProjection()
                ?.fromLatLngToPoint(bounds.getSouthWest());
            if (!worldCoordinateSouthWest) return;
            const additionalPoint = new google.maps.Point(
                worldCoordinateSouthWest.x - (panelWidth + 100) / scale,
                worldCoordinateSouthWest.y,
            );
            const additionalLatLng = map
                .getProjection()
                ?.fromPointToLatLng(additionalPoint);
            if (!additionalLatLng) return;
            bounds.extend(additionalLatLng);
            map.panToBounds(bounds, 24);
        } else {
            map.panTo(defaultCenter);
            map.setZoom(9);
        }
    }, [map, google, clients, focusedClient, panelWidth]);

    useEffect((): void => {
        if (map && google)
            google.maps.event.addListenerOnce(
                map,
                'projection_changed',
                repositionMap,
            );
    }, [map, google, repositionMap]);

    useEffect((): void => {
        repositionMap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedClient]);

    return (
        <>
            {clients.map(
                (c): React.ReactElement => (
                    <PositionElements
                        client={c}
                        focused={c.id === focusedClient}
                        drawPath={drawPath}
                        onMarkerClick={
                            setFocusedClient !== undefined
                                ? () => setFocusedClient(c.id)
                                : undefined
                        }
                        onMouseoverPolyline={
                            onMouseoverPolyline !== undefined
                                ? () => onMouseoverPolyline(c.id)
                                : undefined
                        }
                        onMouseoutPolyline={
                            onMouseoutPolyline !== undefined
                                ? () => onMouseoutPolyline(c.id)
                                : undefined
                        }
                        key={c.id}
                        {...rest}
                    />
                ),
            )}
        </>
    );
};

Positions.defaultProps = {
    focusedClient: undefined,
    setFocusedClient: undefined,
    onMouseoverPolyline: undefined,
    onMouseoutPolyline: undefined,
};

export default Positions;
