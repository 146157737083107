import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import LanguageDropdown from './LanguageDropdown';
import ITheme from '../../interfaces/ITheme';

import logo from '../../images/logo.svg';
import NavLink from './NavLink';
import { useAuth } from '../../oauth/privateRoute';
import LogoutButton from './LogoutButton';
import InfoDialogue from './InfoDialogue';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    header: {
        height: '72px',
        backgroundColor: theme.primary,
        '& > div': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        paddingLeft: 24,
        paddingRight: 40,
    },
    spacer: {
        flexGrow: 1,
    },
    logo: {
        height: '48px',
        width: '48px',
        marginRight: 42,
    },
});

const Header = ({
    classes,
}: {
    classes: Record<string, string>;
}): React.ReactElement => {
    const { t } = useTranslation('translation');
    const auth = useAuth();
    const loggedIn = auth.isSignedIn();
    return (
        <header className={classes.header}>
            <div>
                <img src={logo} alt="Logo" className={classes.logo} />
                <NavLink to="/" label={t('header.emergencyLocator')} />
                {includes(auth.authorities, 'MISSING_PERSON') && (
                    <NavLink
                        to="/missingperson"
                        label={t('header.missingPersonTool')}
                    />
                )}
                <div className={classes.spacer} />
                <InfoDialogue />
                <LanguageDropdown />
                {loggedIn && <LogoutButton />}
            </div>
        </header>
    );
};

export default injectSheet(styles)(Header);
