import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { CoordinateSystem, Measurement } from './ConfigEnums';
import ButtonDialogue from '../ButtonDialogue';
import ITheme from '../../interfaces/ITheme';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    dialogue: {
        '& > div': {
            display: 'flex',
            borderRadius: 4,
            backgroundColor: '#fafaf9',
            '&:not(:last-child)': {
                marginBottom: 12,
            },
            '& > button': {
                '&, &:active, &:hover, &:focus': {
                    textAlign: 'center',
                    flexBasis: '50%',
                    flexGrow: 1,
                    padding: '2px 8px',
                    borderRadius: 4,
                    color: theme.link,
                    fontSize: 12,
                    fontWeight: 'bold',
                    lineHeight: 2,
                    letterSpacing: 0.19,
                    border: 'none',
                    backgroundColor: 'transparent',
                },
                '&.selected': {
                    backgroundColor: theme.lightButton,
                },
            },
        },
    },
});

const ConfigurationDialogue = injectSheet(styles)(
    ({
        measurement,
        setMeasurement,
        coordinateSystem,
        setCoordinateSystem,
        classes,
    }: {
        measurement: Measurement;
        setMeasurement: (m: Measurement) => void;
        coordinateSystem: CoordinateSystem;
        setCoordinateSystem: (c: CoordinateSystem) => void;
        classes: Record<string, string>;
    }): React.ReactElement => {
        const { t } = useTranslation('translation');

        let coordOptions: React.ReactElement[] = [];
        for (let i = 0; i < Object.keys(CoordinateSystem).length; i += 1) {
            const c = Object.keys(CoordinateSystem)[i];
            if (!Number.isNaN(Number(c))) {
                const cEl = (
                    <button
                        type="button"
                        key={c}
                        className={
                            Number(c) === coordinateSystem ? 'selected' : ''
                        }
                        onClick={() => setCoordinateSystem(Number(c))}
                    >
                        {t(
                            `clients.coordinates.${CoordinateSystem[
                                Number(c)
                            ].toLowerCase()}`,
                        )}
                    </button>
                );
                coordOptions = [...coordOptions, cEl];
            }
        }

        let measurementOptions: React.ReactElement[] = [];
        for (let i = 0; i < Object.keys(Measurement).length; i += 1) {
            const m = Object.keys(Measurement)[i];
            if (!Number.isNaN(Number(m))) {
                const mEl = (
                    <button
                        type="button"
                        key={m}
                        className={Number(m) === measurement ? 'selected' : ''}
                        onClick={() => setMeasurement(Number(m))}
                    >
                        {t(
                            `clients.measurements.${Measurement[
                                Number(m)
                            ].toLowerCase()}`,
                        )}
                    </button>
                );
                measurementOptions = [...measurementOptions, mEl];
            }
        }

        const trigger = (
            <Button light small label="Configure">
                {t(
                    `clients.coordinates.${CoordinateSystem[
                        coordinateSystem
                    ].toLowerCase()}`,
                )}{' '}
                |{' '}
                {t(
                    `clients.measurements.short.${Measurement[
                        measurement
                    ].toLowerCase()}`,
                )}
            </Button>
        );

        return (
            <ButtonDialogue trigger={trigger} dialogueClass={classes.dialogue}>
                <div>{coordOptions}</div>
                <div>{measurementOptions}</div>
            </ButtonDialogue>
        );
    },
);

export default ConfigurationDialogue;
