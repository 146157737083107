export enum NotificationState {
    ACTIVE,
    INACTIVE,
}

export enum NotificationType {
    SUCCESS,
    ERROR,
}

export interface INotification {
    text: string;
    type: NotificationType;
}

export interface INotificationWithState extends INotification {
    state: NotificationState;
}
