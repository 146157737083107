import React, { useState } from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import SearchForm from './SearchForm';
import SearchEntry from './SearchEntry';
import { ISearchResult } from '../../../interfaces/ISearchResult';
import ITheme from '../../../interfaces/ITheme';

import triangleIcon from '../../../images/ic-alert-triangle-filled.svg';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    searchCard: {
        backgroundColor: 'white',
        borderRadius: '10px',
        flexGrow: '0',
        marginBottom: 24,
        padding: '16px 17px',
    },
    errorMessage: {
        marginTop: 8,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.29,
        letterSpacing: 0.32,
        color: theme.error,
        '& > img': {
            marginRight: 4,
        },
    },
});

const SearchCard = ({
    onSelect,
    classes,
}: {
    onSelect: (id: number) => void;
    classes: Record<string, string>;
}): React.ReactElement => {
    const [errorMessage, setErrorMessage] = useState('');
    const [foundPersons, setFoundPersons] = useState<ISearchResult[]>([]);
    const { t } = useTranslation('translation');

    const setPersons = (
        persons: ISearchResult[],
        searchResult = false,
    ): void => {
        setFoundPersons(persons);
        if (!searchResult) return;
        if (persons.length === 0) {
            setErrorMessage(t('missingPersonSearch.numberNotFound'));
        } else {
            setErrorMessage('');
        }
    };

    const onPersonSelect = (id: number): void => {
        setFoundPersons([]);
        onSelect(id);
    };

    return (
        <div className={classes.searchCard}>
            <SearchForm
                setFoundPersons={setPersons}
                setErrorMessage={setErrorMessage}
            />
            {errorMessage && (
                <div className={classes.errorMessage}>
                    <img alt="" src={triangleIcon} />
                    <span>{errorMessage}</span>
                </div>
            )}
            {foundPersons !== undefined &&
                foundPersons.length > 0 &&
                foundPersons.map((p) => (
                    <SearchEntry
                        key={p.id}
                        person={p}
                        onSelect={onPersonSelect}
                    />
                ))}
        </div>
    );
};

export default injectSheet(styles)(SearchCard);
