import React, { useState } from 'react';
import injectSheet, { Styles } from 'react-jss';
import { TFunction, withTranslation } from 'react-i18next';
import { i18n as Ti18n } from 'i18next';
import { Button } from '../../Button';
import { NotificationType } from '../../../interfaces/INotification';
import ITheme from '../../../interfaces/ITheme';
import alertTriangle from '../../../images/ic-alert-triangle-filled.svg';
import checkMark from '../../../images/ic-check-filled.svg';
import chevron from '../../../images/ic-chevron.svg';
import { useAuth } from '../../../oauth/privateRoute';

const phoneRegex = /^\+?[0-9()\s-]+$/;
const API = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    container: {
        display: 'flex',
        position: 'relative',
        padding: '23px 18px 54px 17px',
        width: '100%',
        boxSizing: 'border-box',
    },
    field: {
        backgroundColor: '#fafaf9',
        borderRadius: 4,
        border: 'none',
        outline: 'none',
        boxSizing: 'border-box',
        height: 48,
    },
    phoneNumber: {
        extend: 'field',
        width: '100%',
        fontSize: 19,
        lineHeight: 1.63,
        letterSpacing: 0.43,
        padding: '8px 18px 9px',
        caretColor: theme.link,
        '&:focus, &:hover': {
            border: `1px solid ${theme.link}`,
            padding: '7px 17px 8px',
            backgroundColor: 'transparent',
        },
        '&::placeholder': {
            color: '#888880',
        },
    },
    phoneInputWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        marginRight: 19,
    },
    invalidPhoneNumber: {
        extend: 'phoneNumber',
        padding: '7px 17px 8px',
        border: `1px solid ${theme.error}`,
        '&:focus, &:hover': {
            borderColor: theme.error,
        },
    },
    languageSelect: {
        extend: 'field',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.25,
        letterSpacing: 0.36,
        padding: [9, 44, 8, 24],
        marginRight: -8,
        appearance: 'none',
        '&::-ms-expand': {
            display: 'none',
        },
        background: `url(${chevron}) no-repeat right transparent`,
        backgroundOrigin: 'border-box',
        '&:focus': {
            border: `1px solid ${theme.link}`,
            padding: [8, 43, 7, 23],
        },
    },
    message: {
        position: 'absolute',
        bottom: 14,
        left: 17,
        fontSize: 14,
        lineHeight: 1.29,
        letterSpacing: 0.32,
        display: 'flex',
        alignItems: 'center',
        '& > img': {
            marginRight: 4,
        },
    },
    successMessage: {
        extend: 'message',
        color: theme.success,
    },
    errorMessage: {
        extend: 'message',
        color: theme.error,
    },
    button: {
        padding: [14, 20, 15, 21],
        '&.focus-visible': {
            padding: [12, 18, 13, 19],
        },
    },
});

const SmsLocatorForm = ({
    classes,
    t,
    i18n,
}: {
    classes: Record<string, string>;
    t: TFunction<'translation'>;
    i18n: Ti18n;
}): React.ReactElement => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [language, setLanguage] = useState(i18n.language);
    const [message, setMessage] = useState<
        { text: string; type: NotificationType } | undefined
    >(undefined);

    const onChangePhoneNumber = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        setPhoneNumber(event.target.value);
        setMessage(undefined);
    };

    const onChangeLanguage = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ): void => {
        setLanguage(event.target.value);
    };

    const auth = useAuth();

    const onSubmit = (event: React.SyntheticEvent): void => {
        event.preventDefault();
        if (!phoneRegex.test(phoneNumber)) {
            setMessage({
                text: t('emergencyLocator.smsLocator.validationError'),
                type: NotificationType.ERROR,
            });
        } else {
            fetch(`${API}/v1/webapi/locatorlink`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.accessToken}`,
                },
                body: JSON.stringify({
                    userPhoneNumber: phoneNumber.replace(/[()\s-]+/g, ''),
                    language,
                }),
            }).then((response): void => {
                if (response.ok) {
                    setMessage({
                        text: t('emergencyLocator.smsLocator.successMessage'),
                        type: NotificationType.SUCCESS,
                    });
                } else {
                    setMessage({
                        text: `${t(
                            'emergencyLocator.smsLocator.errorMessage',
                        )} ${phoneNumber}`,
                        type: NotificationType.ERROR,
                    });
                }
            });
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <div className={classes.container}>
                <div className={classes.phoneInputWrapper}>
                    <input
                        value={phoneNumber}
                        type="text"
                        name="phoneNumber"
                        onChange={onChangePhoneNumber}
                        className={
                            phoneNumber && !phoneRegex.test(phoneNumber)
                                ? classes.invalidPhoneNumber
                                : classes.phoneNumber
                        }
                        placeholder={t('messages.phonePlaceholder')}
                    />
                </div>
                <select
                    value={language}
                    onChange={onChangeLanguage}
                    className={classes.languageSelect}
                >
                    <option value="de">
                        {t('emergencyLocator.smsLocator.languageOptions.de')}
                    </option>
                    <option value="fr">
                        {t('emergencyLocator.smsLocator.languageOptions.fr')}
                    </option>
                    <option value="it">
                        {t('emergencyLocator.smsLocator.languageOptions.it')}
                    </option>
                    <option value="en">
                        {t('emergencyLocator.smsLocator.languageOptions.en')}
                    </option>
                </select>
                <Button
                    label={t('emergencyLocator.smsLocator.sendAction')}
                    submit
                    disabled={!phoneRegex.test(phoneNumber)}
                    className={classes.button}
                >
                    {t('emergencyLocator.smsLocator.sendAction')}
                </Button>
                {message && (
                    <div
                        className={
                            message.type === NotificationType.SUCCESS
                                ? classes.successMessage
                                : classes.errorMessage
                        }
                    >
                        <img
                            src={
                                message.type === NotificationType.SUCCESS
                                    ? checkMark
                                    : alertTriangle
                            }
                            alt=""
                        />
                        <div>{message.text}</div>
                    </div>
                )}
            </div>
        </form>
    );
};

export default injectSheet(styles)(withTranslation()(SmsLocatorForm));
