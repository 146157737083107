import React, { useState } from 'react';
import { Marker, Polyline, InfoBox, Circle } from '@react-google-maps/api';
import orderBy from 'lodash/orderBy';
import indexOf from 'lodash/indexOf';
import injectSheet from 'react-jss';

import pin from '../../images/map-pin.svg';
import highlightedPin from '../../images/map-pin-highlighted.svg';
import smallPin from '../../images/ic-pin.svg';
import smallPinHighlighted from '../../images/ic-pin-highlighted.svg';
import hoverPin from '../../images/ic-pin-hover.svg';
import endPin from '../../images/ic-pin-end-point.svg';
import endPinHighlighted from '../../images/ic-pin-end-point-highlighted.svg';
import { IClient } from '../../interfaces/IClient';

const PadZero = (n: number): string => {
    if (n > 9) return n.toString();
    return `0${n}`;
};

const styles = {
    hoverBox: {
        backgroundColor: '#fafaf9',
        width: 107,
        height: 57,
        borderRadius: 4,
        textAlign: 'center',
        paddingTop: 11,
        boxSizing: 'border-box',
        '& p': {
            margin: 0,
            '&:first-child': {
                fontSize: 10,
                lineHeight: 1.8,
                letterSpacing: 0.23,
                color: '#888880',
                marginBottom: 2,
            },
            '&:last-child': {
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 1.14,
                letterSpacing: 0.4,
            },
        },
    },
};

const PositionElements = ({
    client,
    focused,
    drawPath,
    onMarkerClick,
    onMouseoverPolyline,
    onMouseoutPolyline,
    classes,
    ...rest
}: {
    client: IClient;
    focused: boolean;
    drawPath: boolean;
    onMarkerClick?: () => void;
    onMouseoverPolyline?: () => void;
    onMouseoutPolyline?: () => void;
    classes: Record<string, string>;
}): React.ReactElement | null => {
    const [hoverStates, setHoverStates] = useState<Record<number, boolean>>({});
    const locations = orderBy(client.locations, ['timestamp'], ['desc']);

    const onMouseOver = (ts: number): void => {
        const newHoverStates = { ...hoverStates };
        newHoverStates[ts] = true;
        if (onMouseoverPolyline !== undefined) onMouseoverPolyline();
        setHoverStates(newHoverStates);
    };

    const onMouseOut = (ts: number): void => {
        const newHoverStates = { ...hoverStates };
        newHoverStates[ts] = false;
        if (
            onMouseoutPolyline !== undefined &&
            indexOf(Object.values(newHoverStates), true) === -1
        )
            onMouseoutPolyline();
        setHoverStates(newHoverStates);
    };

    if (locations.length === 0 || !locations[0].lat || !locations[0].lon)
        return null;

    return (
        <>
            {locations[0].horizontalAccuracy &&
                locations[0].horizontalAccuracy > 0 && (
                    <Circle
                        center={{
                            lat: locations[0].lat,
                            lng: locations[0].lon,
                        }}
                        radius={locations[0].horizontalAccuracy}
                        options={{
                            strokeColor: focused ? '#d20f32' : '#2279ad',
                            strokeOpacity: 0.5,
                            fillColor: focused ? '#d20f32' : '#278ac6',
                        }}
                    />
                )}
            <Marker
                position={{ lat: locations[0].lat, lng: locations[0].lon }}
                onClick={onMarkerClick}
                icon={focused ? highlightedPin : pin}
                {...rest}
            />
            {drawPath && (
                <Polyline
                    path={orderBy(client.locations, ['timestamp'], ['asc']).map(
                        (l) => {
                            return { lat: l.lat, lng: l.lon };
                        },
                    )}
                    options={{
                        strokeColor: focused ? '#d20f32' : '#278ac6',
                        strokeOpacity: 1,
                        strokeWeight: 5,
                    }}
                    {...rest}
                />
            )}
            {drawPath &&
                locations.map((l, i) => {
                    let currentPin = focused ? smallPinHighlighted : smallPin;
                    if (hoverStates[l.timestamp]) currentPin = hoverPin;
                    else if (i === 0)
                        currentPin = focused ? endPinHighlighted : endPin;
                    const date = new Date(l.timestamp);
                    return (
                        <React.Fragment
                            key={`fragment-${client.id}-${l.timestamp}`}
                        >
                            <Marker
                                position={{ lat: l.lat, lng: l.lon }}
                                icon={{
                                    url: currentPin,
                                    anchor: new google.maps.Point(6, 6),
                                }}
                                onMouseOver={() => onMouseOver(l.timestamp)}
                                onMouseOut={() => onMouseOut(l.timestamp)}
                                {...rest}
                            />
                            {hoverStates[l.timestamp] && (
                                <InfoBox
                                    position={{
                                        lat: l.lat,
                                        lng: l.lon,
                                    }}
                                    options={{
                                        pixelOffset: new google.maps.Size(
                                            -53.5,
                                            -72,
                                        ),
                                        closeBoxURL: '',
                                    }}
                                >
                                    <div className={classes.hoverBox}>
                                        <p>{`${PadZero(
                                            date.getDate(),
                                        )}.${PadZero(
                                            date.getMonth() + 1,
                                        )}.${date.getFullYear()}`}</p>
                                        <p>{`${PadZero(
                                            date.getHours(),
                                        )}:${PadZero(
                                            date.getMinutes(),
                                        )}:${PadZero(date.getSeconds())}`}</p>
                                    </div>
                                </InfoBox>
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
};

PositionElements.defaultProps = {
    onMarkerClick: undefined,
    onMouseoverPolyline: undefined,
    onMouseoutPolyline: undefined,
};

export default injectSheet(styles)(PositionElements);
