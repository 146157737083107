import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';
import { IClient } from '../../interfaces/IClient';
import ITheme from '../../interfaces/ITheme';
import {
    INotification,
    NotificationType,
} from '../../interfaces/INotification';
import { CoordinateSystem, Measurement } from './ConfigEnums';
import {
    getCoordinatesString,
    getCopyCoordinatesString,
} from './coordinatesUtils';
import { Button } from '../Button';
import copyIcon from '../../images/ic-copy.svg';
import trashIcon from '../../images/ic-trash-dark.svg';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    clientRow: {
        padding: '10px 19px 13px 33px',
        display: 'grid',
        '&:nth-child(odd)': {
            backgroundColor: '#fafaf9',
        },
        gridTemplateColumns: '188px 237px 1fr max-content',
        gridTemplateRows: 'max-content max-content max-content max-content',
    },
    clientRowHover: {
        extend: 'clientRow',
        '&:hover': {
            backgroundColor: '#eff5f8 !important',
        },
    },
    activeclientRow: {
        extend: 'clientRow',
        backgroundColor: '#eff5f8 !important',
    },
    copyButton: {
        padding: 3,
        height: 24,
        position: 'absolute',
        top: -2,
        right: 0,
        backgroundColor: 'transparent',
        '&:active, &:hover, &:focus': {
            backgroundColor: theme.lightButton,
        },
        '&:focus': {
            border: 'none',
            boxShadow: 'none',
            padding: 3,
        },
        '& > img': {
            height: 18,
            width: 18,
        },
    },
    deleteButton: {
        gridColumn: 4,
        gridRow: '1 / -1',
        padding: 4,
        marginTop: 28,
        backgroundColor: 'transparent',
        '&:active, &:hover, &:focus': {
            backgroundColor: theme.lightButton,
        },
        '&:focus': {
            border: 'none',
            boxShadow: 'none',
            padding: 4,
        },
    },
    text: {
        fontSize: 14,
        lineHeight: 1.14,
        letterSpacing: 0.4,
    },
    textBold: {
        extend: 'text',
        fontWeight: 'bold',
    },
    textSmall: {
        fontSize: 12,
        lineHeight: 1.17,
        letterSpacing: 0.34,
    },
    label: {
        fontSize: 10,
        lineHeight: 1.2,
        letterSpacing: 0.23,
        color: '#888880',
        minHeight: 12,
    },
    timeStamp: {
        extend: 'label',
        lineHeight: 1.8,
        gridColumn: 1 / 3,
        gridRow: 1,
        paddingBottom: 13,
    },
    phoneNumber: {
        extend: 'text',
        gridColumn: 1,
        gridRow: 2,
        marginRight: 35,
    },
    name: {
        extend: 'label',
        gridColumn: 1,
        gridRow: 3,
        marginRight: 35,
        paddingTop: 2,
        paddingBottom: 13,
    },
    deviceInfo: {
        extend: 'textSmall',
        gridColumn: 1,
        gridRow: 4,
        marginRight: 35,
    },
    coordinates: {
        extend: 'textBold',
        gridColumn: 2,
        gridRow: 2,
        position: 'relative',
        paddingRight: 29,
        marginRight: 21,
    },
    horizontalAccuracy: {
        extend: 'label',
        gridColumn: 2,
        gridRow: 3,
        paddingTop: 2,
        marginRight: 21,
        paddingBottom: 13,
    },
    address: {
        extend: 'textSmall',
        gridColumn: 2,
        gridRow: 4,
        marginRight: 21,
    },
    height: {
        extend: 'textSmall',
        gridColumn: 3,
        gridRow: 2,
    },
    verticalAccuracy: {
        extend: 'label',
        gridColumn: 3,
        gridRow: 3,
        paddingTop: 3,
        paddingBottom: 13,
    },
});

const ClientRow = injectSheet(styles)(
    ({
        classes,
        client,
        measurement,
        coordinateSystem,
        addNotification,
        onClick,
        onDelete,
        active,
    }: {
        classes: Record<string, string>;
        client: IClient;
        measurement: Measurement;
        coordinateSystem: CoordinateSystem;
        addNotification: (n: INotification) => void;
        onClick?: () => void;
        onDelete?: (id: number) => void;
        active?: boolean;
    }): React.ReactElement => {
        const { t } = useTranslation('translation');
        const date = new Date(client.timestamp);

        const PadZero = (n: number): string => {
            if (n > 9) return n.toString();
            return `0${n}`;
        };

        const MeasurementToString = (value: number): string => {
            const v = Math.round(
                measurement === Measurement.METER ? value : value * 3.280824,
            );
            return `${v} ${t(
                `clients.measurements.short.${Measurement[
                    measurement
                ].toLowerCase()}`,
            )}`;
        };

        const location = orderBy(client.locations, ['timestamp'], ['desc'])[0];

        let className = classes.clientRow;
        if (active) className = classes.activeclientRow;
        else if (onClick !== undefined) className = classes.clientRowHover;
        return (
            <div
                className={className}
                onClick={onClick}
                onKeyDown={onClick}
                role="menuitem"
                tabIndex={0}
            >
                <div className={classes.timeStamp}>
                    {client.timestamp &&
                        client.timestamp !== -Infinity &&
                        `${PadZero(date.getHours())}:${PadZero(
                            date.getMinutes(),
                        )}:${PadZero(date.getSeconds())} | ${PadZero(
                            date.getDate(),
                        )}.${PadZero(date.getMonth() + 1)}.${PadZero(
                            date.getFullYear(),
                        )}`}
                </div>
                <div className={classes.phoneNumber}>{client.phoneNumber}</div>
                <div className={classes.name}>{client.name}</div>
                <div className={classes.deviceInfo}>
                    {`${
                        typeof client.device.batteryLevel === 'number'
                            ? `${t('clients.battery')} ${Math.round(
                                  client.device.batteryLevel * 100,
                              )}% | `
                            : ''
                    }${
                        client.device.source
                            ? t(
                                  `clients.source.${client.device.source.toLowerCase()}`,
                              )
                            : 'unknown'
                    } | ${
                        client.device.appLanguage
                            ? client.device.appLanguage.toUpperCase()
                            : 'unknown'
                    }${
                        client.additionalProperties.goennerNumber
                            ? ` | ${t('clients.goennerNumber')}: ${
                                  client.additionalProperties.goennerNumber
                              }`
                            : ''
                    }`}
                </div>

                <div className={classes.coordinates}>
                    {location && location.lat && location.lon
                        ? getCoordinatesString(
                              location.lat,
                              location.lon,
                              coordinateSystem,
                          )
                        : ''}
                    {location && location.lat && location.lon && (
                        <Button
                            label="copy"
                            small
                            light
                            className={classes.copyButton}
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                }
                                try {
                                    navigator.clipboard
                                        .writeText(
                                            getCopyCoordinatesString(
                                                location.lat,
                                                location.lon,
                                                coordinateSystem,
                                            ),
                                        )
                                        .then(
                                            () =>
                                                addNotification({
                                                    text: t(
                                                        'messages.coordinatesCopied',
                                                    ),
                                                    type: NotificationType.SUCCESS,
                                                }),
                                            () =>
                                                addNotification({
                                                    text: t(
                                                        'messages.coordinatesNotCopied',
                                                    ),
                                                    type: NotificationType.ERROR,
                                                }),
                                        );
                                } catch {
                                    addNotification({
                                        text: t(
                                            'messages.coordinatesNotCopied',
                                        ),
                                        type: NotificationType.ERROR,
                                    });
                                }
                            }}
                        >
                            <img alt="copy" src={copyIcon} />
                        </Button>
                    )}
                </div>
                <div className={classes.horizontalAccuracy}>
                    {location &&
                        location.horizontalAccuracy &&
                        `±${MeasurementToString(location.horizontalAccuracy)}`}
                </div>
                <div className={classes.address}>
                    {location && location.address}
                </div>

                <div className={classes.height}>
                    {location &&
                        `h ${MeasurementToString(
                            Math.round(location.altitude),
                        )}`}
                </div>
                <div className={classes.verticalAccuracy}>
                    {location &&
                        location.verticalAccuracy &&
                        `±${MeasurementToString(location.verticalAccuracy)}`}
                </div>

                {onDelete !== undefined && (
                    <Button
                        label={t('actions.delete')}
                        small
                        light
                        onClick={() => {
                            onDelete(client.id);
                        }}
                        className={classes.deleteButton}
                    >
                        <img src={trashIcon} alt={t('actions.delete')} />
                    </Button>
                )}
            </div>
        );
    },
);

ClientRow.defaultProps = {
    active: false,
};

export default ClientRow;
