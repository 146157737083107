import React, { useState } from 'react';
import injectSheet from 'react-jss';
import { useRecoilState } from 'recoil';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import { Message } from '@stomp/stompjs';
import { IClient } from '../../interfaces/IClient';
import Map from '../Map/Map';
import SearchCard from './SearchCard/SearchCard';
import ClientsCard from '../Clients/ClientsCard';
import { IMissingPerson } from '../../interfaces/IMissingPerson';
import { useAuth } from '../../oauth/privateRoute';
import { trackedPersonState } from '../../state/atoms';
import useStompWS from '../../stomp/useStompWS';

const API = process.env.REACT_APP_API_URL_MISSING_PERSON
    ? process.env.REACT_APP_API_URL_MISSING_PERSON
    : '';
const PANEL_WIDTH = 604;

const styles = {
    dashboard: {
        width: '100%',
        height: 'calc(100vh - 72px)',
        position: 'relative',
    },
    panel: {
        width: `${PANEL_WIDTH - 24}px`,
        position: 'absolute',
        top: '40px',
        left: '24px',
        height: 'calc(100% - 80px)',
        display: 'flex',
        flexDirection: 'column',
    },
};

const MissingPersonSearch = ({
    classes,
}: {
    classes: Record<string, string>;
}): React.ReactElement => {
    const [connected, setConnected] = useState(false);
    const [clients, setClients] = useState<IClient[]>([]);
    const [focusedClient, setFocusedClient] = useState<number | undefined>(
        undefined,
    );
    const [highlightedClient, setHighlightedClient] = useState<
        number | undefined
    >(undefined);
    const auth = useAuth();

    const [trackedPersonIds, setTrackedPersonIds] =
        useRecoilState(trackedPersonState);

    const onMessage = (m: Message): void => {
        const message: IMissingPerson | undefined = JSON.parse(m.body);
        if (!message) return;
        const newClient = {
            id: message.personId,
            timestamp: Math.max(...message.locations.map((l) => l.timestamp)),
            name: message.caller.name ? message.caller.name : '',
            phoneNumber: message.caller.phoneNumber,
            device: message.device,
            locations: message.locations,
            additionalProperties: {},
        };
        const index = findIndex(clients, { id: message.personId });
        if (index === -1) {
            setClients([...clients, newClient]);
        } else {
            const updatedClients = [...clients];
            updatedClients[index] = newClient;
            setClients(updatedClients);
        }
    };

    useStompWS({
        url: `${API.replace(/^(http)/, 'ws')}/v1/live`,
        topics: trackedPersonIds.map((id) => {
            return { topic: `/${id}`, onMessage };
        }),
        connectHeaders: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        subscribeHeaders: {
            Authorization: `Bearer ${auth.user?.accessToken}`,
        },
        onConnect: () => {
            setClients([]);
            setConnected(true);
        },
        onDisconnect: () => setConnected(false),
    });

    const onSelectSearchEntry = (personId: number): void => {
        setTrackedPersonIds([...trackedPersonIds, personId]);
    };

    const onDeleteEntry = (id: number): void => {
        setTrackedPersonIds(filter(trackedPersonIds, (i) => i !== id));
        setClients(filter(clients, (c) => c.id !== id));
    };

    const onMouseoverPolyline = (id: number): void => {
        setHighlightedClient(id);
    };

    const onMouseoutPolyline = (id: number): void => {
        if (highlightedClient === id) setHighlightedClient(undefined);
    };

    return (
        <div className={classes.dashboard}>
            <Map
                panelWidth={PANEL_WIDTH}
                clients={clients}
                drawPath
                onMouseoutPolyline={onMouseoutPolyline}
                onMouseoverPolyline={onMouseoverPolyline}
                focusedClient={focusedClient}
            />
            <div className={classes.panel}>
                <SearchCard onSelect={onSelectSearchEntry} />
                {clients.length > 0 && (
                    <ClientsCard
                        clients={clients}
                        connected={connected}
                        onDeleteOne={onDeleteEntry}
                        focusedClient={focusedClient}
                        onFocus={setFocusedClient}
                        highlightedClient={highlightedClient}
                    />
                )}
            </div>
        </div>
    );
};

export default injectSheet(styles)(MissingPersonSearch);
