import React from 'react';
import ReactDOM from 'react-dom';
import injectSheet, { ThemeProvider, Styles } from 'react-jss';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import 'whatwg-fetch'; // fetch polyfill
import 'focus-visible'; // :focus-visible polyfill
import 'focus-within-polyfill'; // :focus-within polyfill
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import translationsDe from './translations/de/translations.json';
import translationsEn from './translations/en/translations.json';
import translationsFr from './translations/fr/translations.json';
import translationsIt from './translations/it/translations.json';
import { AuthenticationProvider } from './oauth/privateRoute';
import ITheme from './interfaces/ITheme';

i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
    resources: {
        de: {
            translation: translationsDe,
        },
        fr: {
            translation: translationsFr,
        },
        it: {
            translation: translationsIt,
        },
        en: {
            translation: translationsEn,
        },
    },
    lng: 'de',
    fallbackLng: 'de',
});

const globalTheme: ITheme = {
    primary: '#14233c',
    primaryHover: '#0c1525',
    secondary: '#d20f32',
    light: '#ffffff',
    link: '#278ac6',
    linkHover: '#2279ad',
    lightButton: '#d4e8f4',
    error: '#d20f32',
    success: '#4a964d',
};

const globalStyles = (theme: ITheme): Styles<string, unknown> => ({
    '@global': {
        body: {
            color: theme.primary,
        },
        a: {
            color: theme.link,
            '&:hover, &:active, &:focus': {
                color: theme.linkHover,
            },
            '&:active, &:focus': {
                borderStyle: 'solid',
                borderColor: theme.link,
                borderWidth: '1px',
            },
        },
    },
});

const StyledApp = injectSheet(globalStyles)(App);

ReactDOM.render(
    <React.StrictMode>
        <AuthenticationProvider>
            <ThemeProvider theme={globalTheme}>
                <StyledApp />
            </ThemeProvider>
        </AuthenticationProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
