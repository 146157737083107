import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import ButtonDialogue from '../ButtonDialogue';
import ITheme from '../../interfaces/ITheme';
import trashIconDark from '../../images/ic-trash-dark.svg';
import trashIconLight from '../../images/ic-trash-light.svg';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    trigger: {
        padding: 4,
        marginLeft: 24,
        '&.focus-visible': {
            padding: 2,
        },
    },
    dialogue: {
        textAlign: 'center',
        width: 156,
        '& > p': {
            fontSize: 14,
            lineHeight: 1.43,
            letterSpacing: 0.4,
            '& > button': {
                fontWeight: 800,
                lineHeight: 1.71,
                letterSpacing: 0.22,
                color: theme.link,
                marginTop: 16,
                border: 'none',
                backgroundColor: 'transparent',
                borderRadius: 4,
                padding: '4px 8px',
                '&:hover, &:active, &:focus': {
                    backgroundColor: theme.lightButton,
                },
            },
        },
    },
});

const DeletionDialogue = ({
    onDelete,
    classes,
}: {
    onDelete: () => void;
    classes: Record<string, string>;
}): React.ReactElement => {
    const { t } = useTranslation('translation');
    const trigger = (
        <Button className={classes.trigger} light small label="Delete">
            <img src={trashIconDark} alt="Delete" className="dark" />
            <img src={trashIconLight} alt="Delete" className="light" />
        </Button>
    );
    return (
        <ButtonDialogue
            trigger={trigger}
            dialogueClass={classes.dialogue}
            closeOnChildAction
        >
            <p>{t('clients.deleteConfirmation')}</p>
            <p>
                <button
                    type="button"
                    aria-label={t('actions.delete')}
                    onClick={onDelete}
                >
                    {t('actions.delete')}
                </button>
            </p>
        </ButtonDialogue>
    );
};

export default injectSheet(styles)(DeletionDialogue);
