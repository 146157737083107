import React from 'react';
import injectSheet, { Styles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import ITheme from '../../interfaces/ITheme';
import ButtonDialogue from '../ButtonDialogue';
import DocumentIcon from '../../images/ic-file.svg';
import MailIcon from '../../images/ic-mail-s.svg';

const styles = (theme: ITheme): Styles<string, unknown> => ({
    wrapper: {
        height: '100%',
        position: 'relative',
    },
    trigger: {
        border: 'none',
        backgroundColor: 'transparent',
        height: '100%',
        padding: '19px 24px',
        fontSize: 14,
        lineHeight: 2.43,
        letterSpacing: 0.4,
        color: theme.light,
        '&:hover, &.active': {
            backgroundColor: theme.primaryHover,
        },
        '&.focus-visible': {
            border: `2px solid ${theme.light}`,
            padding: '17px 22px',
        },
        '&.active $arrow': {
            transform: 'rotate(-135deg)',
        },
    },
    arrow: {
        border: `solid ${theme.light}`,
        borderWidth: '0 2px 2px 0',
        display: 'inline-block',
        padding: 3,
        transform: 'rotate(45deg)',
        transition: 'transform 0.2s',
        marginLeft: 20,
    },
    dialogue: {
        padding: [6, 16, 16],
        top: 79,
        right: -171,
        '& > a': {
            display: 'block',
            textDecoration: 'none',
            '& > div': {
                marginTop: 10,
                display: 'flex',
                alignItems: 'center',
                height: 48,
                padding: '12px 41px 12px 8px',
                boxSizing: 'border-box',
                boxShadow: '0 1px 6px 1px rgba(0, 0, 0, 0.1)',
                '& > img': {
                    width: 24,
                    height: 24,
                },
                '& > div': {
                    marginLeft: 10,
                    fontSize: 14,
                    lineHeight: 1.43,
                    letterSpacing: 0.4,
                    whiteSpace: 'nowrap',
                    color: theme.primary,
                },
            },
            '&:hover > div': {
                backgroundColor: '#eff5f8',
            },
        },
    },
});

const InfoDialogue = ({
    classes,
}: {
    classes: Record<string, string>;
}): React.ReactElement => {
    const { t, i18n } = useTranslation('translation');

    const trigger = (
        <button
            type="button"
            aria-label={t('header.info.trigger')}
            className={classes.trigger}
        >
            {t('header.info.trigger')}
            <span className={classes.arrow} />
        </button>
    );

    return (
        <div className={classes.wrapper}>
            <ButtonDialogue trigger={trigger} dialogueClass={classes.dialogue}>
                <a href={`/files/specifications_${i18n.language}.pdf`} download>
                    <div>
                        <img src={DocumentIcon} alt="PDF File" />
                        <div>{t('header.info.documents.specifications')}</div>
                    </div>
                </a>
                <a href={`/files/handout_${i18n.language}.pdf`} download>
                    <div>
                        <img src={DocumentIcon} alt="PDF File" />
                        <div>{t('header.info.documents.handout')}</div>
                    </div>
                </a>
                <a href={`mailto:${t('app.email')}`}>
                    <div>
                        <img src={MailIcon} alt="" />
                        <div>{t('app.email')}</div>
                    </div>
                </a>
            </ButtonDialogue>
        </div>
    );
};

export default injectSheet(styles)(InfoDialogue);
