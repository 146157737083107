import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { IClient } from '../../interfaces/IClient';
import Positions from './Positions';

export const defaultCenter = { lat: 46.8182, lng: 8.2275 };

const mapUiOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: 'satellite',
    mapTypeControl: true,
    styles: [
        {
            stylers: [
                {
                    saturation: -10,
                },
            ],
        },
        // {
        //     stylers: [
        //         {
        //             lightness: 100,
        //         },
        //         {
        //             visibility: 'simplified',
        //         },
        //     ],
        //     elementType: 'geometry',
        //     featureType: 'road',
        // }
    ],
    maxZoom: 21,
};

const Map = ({
    panelWidth,
    clients,
    focusedClient,
    setFocusedClient,
    drawPath,
    onMouseoverPolyline,
    onMouseoutPolyline,
}: {
    panelWidth: number;
    clients: IClient[];
    focusedClient?: number;
    setFocusedClient?: (i: number) => void;
    drawPath?: boolean;
    onMouseoverPolyline?: (i: number) => void;
    onMouseoutPolyline?: (i: number) => void;
}): React.ReactElement | null => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY
            ? process.env.REACT_APP_MAP_API_KEY
            : '',
        version: 'quarterly',
    });
    const { google } = window;

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{
                width: '100%',
                height: '100%',
            }}
            center={defaultCenter}
            zoom={9}
            options={{
                ...mapUiOptions,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
            }}
        >
            <Positions
                panelWidth={panelWidth}
                clients={clients}
                focusedClient={focusedClient}
                setFocusedClient={setFocusedClient}
                drawPath={!!drawPath}
                onMouseoverPolyline={onMouseoverPolyline}
                onMouseoutPolyline={onMouseoutPolyline}
            />
        </GoogleMap>
    ) : null;
};

Map.defaultProps = {
    drawPath: false,
    focusedClient: undefined,
    setFocusedClient: undefined,
    onMouseoverPolyline: undefined,
    onMouseoutPolyline: undefined,
};

export default Map;
